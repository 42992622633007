import React, { Component } from "react"

class ErrorRedirect extends Component {
  componentDidMount() {
    const id = window.location.pathname.replace("/", "")
    window.location = `http://localhost:7071/Api/Go/${id}`

    //window.location.replace("http://localhost:7071/api/go/t6lIdqaZL")

    //window.location.replace(`/Api/Go/${id}`)

    // http://localhost:8000/Api/Go/t6lIdqaZL

    //window.location.assign(`http://localhost:8000/Api/Go/${id}`)

    // axios
    //   .get(`/api/info/${id}`)
    //   .then(function (response) {
    //     window.location.replace(response.data.longUrl)
    //   })
    //   .catch(function (error) {
    //     window.location.replace("/NotFound")
    //   })
  }

  render() {
    return <></>
  }
}

export default ErrorRedirect
